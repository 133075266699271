module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-wordpress-inline-images/gatsby-browser.js'),
      options: {"plugins":[],"baseUrl":"34.66.52.17","protocol":"http"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Haus of Steve","short_name":"Haus of Steve","start_url":"/","background_color":"#333333","theme_color":"#333333","display":"standalone","icon":"src/img/smoker.png","crossOrigin":"use-credentials"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-121901918-1"},
    }]
